
import { defineComponent, ref, PropType, toRefs } from 'vue';
import Multiselect from '@vueform/multiselect';
import { BussinesClientByNewOrderObject } from '@/models/Interfaces';
import { CrmCustomerEditorModel } from '@/models/CrmCustomers';
import Calendar from 'primevue/calendar';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        modelValue: {
            type: Object as PropType<CrmCustomerEditorModel>,
            required: true
        },
        bussinesClients: {
            type: Array as PropType<BussinesClientByNewOrderObject[]>,
            required: true
        },
        calendarBirthdate: {
            type: Object as PropType<Date | null>,
            required: true
        }
    },
    emits: ['update:modelValue'],
    components: {
        Multiselect,
        Calendar
    },
    name: 'CrmCustomerForm',
    async setup(props: any, { emit }: any) {
        const { t } = useI18n();
        const originCrmCustomer = ref(new CrmCustomerEditorModel());
        const { modelValue, calendarBirthdate } = toRefs(props);
        const updateModel = (key: keyof CrmCustomerEditorModel, value: any) => {
            modelValue.value[key] = value;
            emit('update:modelValue', modelValue.value);
        };
        function birthDateChanged(event: Date) {
            emit('birthDateChanged', event);
        }
        const firstNameField = ref<HTMLInputElement>();
        const firstNameFieldError = ref<HTMLInputElement>();
        const lastNameField = ref<HTMLInputElement>();
        const lastNameFieldError = ref<HTMLInputElement>();
        const emailField = ref<HTMLInputElement>();
        const emailFieldError = ref<HTMLInputElement>();
        const phoneField = ref<HTMLInputElement>();
        const phoneFieldError = ref<HTMLInputElement>();

        function setValidator(codes: { key: string; msg: string }[]) {
            const firstNameError = codes.find(x => x.key == 'FirstName');
            if (firstNameError) {
                firstNameField.value?.setCustomValidity(firstNameError.msg);
                if (firstNameFieldError.value) {
                    firstNameFieldError.value.textContent = firstNameError.msg;
                }
            }
            const lastNameError = codes.find(x => x.key == 'LastName');
            if (lastNameError) {
                lastNameField.value?.setCustomValidity(lastNameError.msg);
                if (lastNameFieldError.value) {
                    lastNameFieldError.value.textContent = lastNameError.msg;
                }
            }
            const emailError = codes.find(x => x.key == 'Email');
            if (emailError) {
                emailField.value?.setCustomValidity(emailError.msg);
                if (emailFieldError.value) {
                    emailFieldError.value.textContent = emailError.msg;
                }
            }
            const phoneError = codes.find(x => x.key == 'Mobile');
            if (phoneError) {
                phoneField.value?.setCustomValidity(phoneError.msg);
                if (phoneFieldError.value) {
                    phoneFieldError.value.textContent = phoneError.msg;
                }
            }
            // birthdate validation
            const birthdateError = codes.find(x => x.key == 'Birthdate');
            if (birthdateError) {
                const birthdateInput = document.getElementById('birthdateField') as HTMLInputElement;
                const birthdateFieldError = document.getElementById('birthdateFieldError') as HTMLInputElement;
                const firstSpan = birthdateFieldError.querySelector('span') as HTMLInputElement;
                birthdateInput.style.setProperty('border', '1px solid red', 'important');
                birthdateInput.setCustomValidity(birthdateError.msg);
                birthdateFieldError.style.setProperty('display', 'block', 'important');
                firstSpan.textContent = birthdateError.msg;
            }
        }
        function clearValidator() {
            // required
            // firstName validation
            firstNameField.value?.setCustomValidity('');
            if (firstNameFieldError.value) {
                firstNameFieldError.value.textContent = t('valid.isRequired');
            }

            // required
            // lastName validation
            lastNameField.value?.setCustomValidity('');
            if (lastNameFieldError.value) {
                lastNameFieldError.value.textContent = t('valid.isRequired');
            }

            // email validation
            emailField.value?.setCustomValidity('');
            if (emailFieldError.value) {
                emailFieldError.value.textContent = t('valid.isRequired');
            }

            // required
            // phone validation
            phoneField.value?.setCustomValidity('');
            if (phoneFieldError.value) {
                phoneFieldError.value.textContent = t('valid.isRequired');
            }

            // birthdate validation
            const birthdateInput = document.getElementById('birthdateField') as HTMLInputElement;
            if (birthdateInput) {
                birthdateInput.setCustomValidity('');
                birthdateInput.style.setProperty('border', '1px solid #a8e12a', 'important');
            }
            const birthdateInputError = document.getElementById('birthdateFieldError') as HTMLInputElement;
            if (birthdateInputError) {
                birthdateInputError.style.setProperty('display', 'none', 'important');
            }
        }

        return {
            close,
            birthDateChanged,
            calendarBirthdateRef: calendarBirthdate,
            originCrmCustomer,
            crmCustomer: modelValue,
            updateModel,
            phoneField,
            phoneFieldError,
            emailField,
            emailFieldError,
            lastNameField,
            lastNameFieldError,
            firstNameField,
            firstNameFieldError,
            clearValidator,
            setValidator
        };
    }
});
