import { ComparisonOperator, ReminderSaveChanges, CrmCustomerMessageType, CrmCustomerHistoryTypes, LeadStatus } from '@/models/Enums';
export class CrmCustomerEditorModel {
    id: string | null = null;
    firstName = '';
    lastName = '';
    birthdate: string | null = null;
    businessClientId: number | null = null;
    email = '';
    mobile = '';
    businessClientName = '';
}

export class CrmCustomerDetailsModel {
    id = '';
    firstName = '';
    lastName = '';
    fullName = '';
    birthdate: string | null = null;
    businessClientId: number | null = null;
    businessClientName = '';
    email = '';
    mobile = '';
    remarks = '';
    createdAt = '';
    lastOrderDate = '';
    orderTotalQuantity = 0;
    ordersTotalAmount = 0;
    leadTotalQuantity = 0;
    reminders: CrmCustomerReminderDetailsModel[] = [];
    messages: CrmCustomerMessage[] = [];
    timingChatMessage: CrmCustomerTimingChatMessage[] = [];
    histories: CrmCustomerHistory[] = [];
    existMoreHistories = false;
    orders: CrmCustomerOrder[] = [];
    existMoreOrders = false;
    existMoreLeads = false;
    activities: CrmCustomerDetailsActivity[] = [];
    existMoreActivities = false;
    leads: CrmCustomerLead[] = [];
}
export class CrmCustomerReminderDetailsModel {
    id = '';
    name = '';
    alertDateTime = '';
    active = true;
    onlyForMe = true;
    relativeInterval = '';
}

export class UserReminder {
    id = '';
    name = '';
    alertDateTime = '';
    active = true;
    crmCustomerFullName = true;
    crmCustomerId = '';
    relativeInterval = '';
    more = false;
}

export class CrmCustomerListModel {
    id = '';
    firstName = '';
    lastName = '';
    fullName = '';
    email = '';
    businessClientName = '';
    businessClientId: number | null = null;
    phone = '';
    orderTotalAmount = 0;
    lastChatDate = '';
    lastOrderCreateDate = '';
    orderTotalQuantity = 0;
    leadTotalQuantity = 0;
    createdAt = '';
    remarks = '';
    unreadMessageCount = 0;
}

export class CrmCustomerListFilter {
    pageIndex = 0;
    keywords = '';
    orderBy = 'Id';
    asc = false;
    businessClientId: number | null = null;
    createDateFrom: string | null = null;
    createDateTo: string | null = null;
    lastOrderDateFrom: string | null = null;
    lastOrderDateTo: string | null = null;
    leadTotalQuantity: number | null = null;
    leadTotalQuantityComparison = ComparisonOperator.LessThan;
    orderTotalAmount: number | null = null;
    orderTotalAmountComparison = ComparisonOperator.LessThan;
    orderTotalQuantity: number | null = null;
    orderTotalQuantityComparison = ComparisonOperator.LessThan;
}

export class CrmCustomerReminderEditorModel {
    id: string | null = null;
    name = '';
    active = true;
    onlyForMe = false;
    alertDate: string | null = null;
    alertTime: string | null = null;
    relativeInterval = '';
}

export class CrmCustomerReminderSaveRequest {
    id: string | null = null;
    name = '';
    active = true;
    onlyForMe = false;
    alertDateTime: string | null = null;
    crmCustomerId = '';
    changes: ReminderSaveChanges | null = null;
    crmCustomerLeadId: string | null = null;
}
export class CrmCustomerMessage {
    id: string;
    createdAt: string;
    from: string;
    isMe: boolean;
    message: string;
    attachments: CrmCustomerMessageAttachment[];
    type: CrmCustomerMessageType | null;
    messageValues: Record<string, unknown> | null;
    constructor() {
        this.id = '';
        this.createdAt = '';
        this.from = '';
        this.isMe = false;
        this.message = '';
        this.type = null;
        this.messageValues = null;
        this.attachments = [];
    }
}
export class CrmCustomerTimingChatMessage {
    id = '';
    message = '';
    scheduleDateTime = '';
    relativeInterval = '';
}
export class CrmCustomerHistory {
    id = '';
    type = CrmCustomerHistoryTypes.Sms;
    payload: any = {};
    createdAt = '';
    showAll = false;
}
export class CrmCustomerLead {
    id = '';
    status = LeadStatus.New;
    leadTypeName = '';
    leadSourceName = '';
    createdAtDate = '';
    createdAtTime = '';
    unreadedMessages = 0;
}
export class CrmCustomerOrder {
    id = '';
    orderNumber = '';
    productName = '';
    arrivalDate = '';
    arrivalTime = '';
    arrivalDuration = '';
    totalAmount = '';
    currencySymbol = '';
    reservationDate = '';
    reservationTime = '';
    statusId = 0;
}
export class CrmCustomerTimingChatMessageEditModel {
    id: string | null = null;
    message = '';
    scheduleDate = '';
    scheduleTime = '';
    relativeInterval = '';
}

export class QuickReplyMessageEditModel {
    id: string | null = null;
    message = '';
    leadTypeId: string | null = null;
    leadTypeName = '';
}

export class QuickReplyMessageViewModel {
    id = '';
    message = '';
    leadTypeId: string | null = null;
    leadTypeName = '';
}

export class CrmCustomerMessageAttachment {
    id = '';
    fileName = '';
}
export class CrmCustomerActivity {
    id = '';
    date = '';
    time = '';
    active = true;
    typeId = '';
    locationId = '';
    subject = '';
    remarks = '';
}
export class CrmCustomerDetailsActivity {
    id = '';
    dateTime = '';
    active = true;
    typeId = '';
    locationId = '';
    subject = '';
    remarks = '';
    locationName = '';
    typeName = '';
}
export interface CrmCustomerActivityTagEditModel {
    id: string | null;
    name: string;
    active: boolean;
    editMode: boolean;
    copy: CrmCustomerActivityCopy;
}
export class IdNameActive {
    id = '';
    name = '';
    active = true;
}
export class CrmCustomerActivityCopy {
    name = '';
    active = true;
}
export class CrmCustomerLeadListFilter {
    reference: number | null = null;
    keywords = '';
    orderBy = 'Id';
    asc = false;
    businessClientId: number | null = null;
    leadTypeId: number | null = null;
    leadSourceId: number | null = null;
    status: number | null = null;
    quoteTotalQuantity: number | null = null;
    quoteTotalQuantityComparison = ComparisonOperator.LessThan;
    createDateFrom: string | null = null;
    createDateTo: string | null = null;
}
export class CrmCustomerLeadListModel {
    id = '';
    crmCustomerId = '';
    crmCustomerFullName = '';
    businessClientName = '';
    businessClientId: number | null = null;
    mobile = '';
    createdAt = '';
    leadTypeName = '';
    leadSourceName = '';
    quoteTotalQuantity = 0;
    description = '';
    status: LeadStatus = LeadStatus.New;
    unreadMessageCount = 0;
    lastQuoteDate = '';
    lastChatDate = '';
    leadRemarks = '';
    lastQuoteMessage = '';
}
export class CrmCustomerLeadEditorModel {
    id: string | null = null;
    crmCustomerId: string | null = null;
    numberOfGuests: string | number = '';
    description = '';
    remarks = '';
    active = true;
    leadTypeId: string | null = null;
    status = LeadStatus.New;
    leadSourceId: string | null = null;
}
export class CrmCustomerLeadDetailsModel {
    id = '';
    messages: CrmCustomerMessage[] = [];
    status = LeadStatus.New;
    active = true;
    remarks = '';
    leadTypeId = '';
    leadTypeName = '';
    leadSourceId = '';
    leadSourceName = '';
    createdAt = '';
    numberOfGuests = 0;
    description = '';
    crmCustomerId = '';
    crmCustomerFullName = '';
    crmCustomerLastName = '';
    crmCustomerFirstName = '';
    crmCustomerMobile = '';
    crmCustomerEmail = '';
    crmCustomerBusinessClientName = '';
    crmCustomerBusinessClientId: number | null = null;
    crmCustomerBirthdate: string | null = null;
    timingChatMessages: CrmCustomerTimingChatMessage[] = [];
    reminders: CrmCustomerReminderDetailsModel[] = [];
}
export interface CrmCustomerLeadToUpdateDetalis {
    description: string;
    numberOfGuests: number;
    leadTypeId: string;
    leadSourceId: string;
    leadTypeName: string;
    leadSourceName: string;
}
