
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import swal from 'sweetalert2';
import { BussinesClientByNewOrderObject, SaveCrmCustomerRequest } from '@/models/Interfaces';
import { generalStore } from '@/store';
import { CrmCustomerEditorModel } from '@/models/CrmCustomers';
import router from '@/router';
import { api } from '@/services/Api';
import { DateConstants } from '@/models/Constants';
import moment from 'moment';
import q from 'q';
import { CrmCustomerSaveChanges } from '@/models/Enums';
import _ from 'lodash';
import { getFlagChanges } from '@/helper';
import CrmCustomerForm from '@/components/CrmCustomers/CrmCustomerForm.vue';
import { getBirthdateString } from '@/helper';

export default defineComponent({
    components: {
        OverlayModal,
        CrmCustomerForm
    },
    name: 'CrmCustomerEditorModal',
    async setup(props: any, { emit }: any) {
        const isOpen = ref(false);
        const bussinesClients = ref<BussinesClientByNewOrderObject[]>([]);
        const originCrmCustomer = ref(new CrmCustomerEditorModel());
        const crmCustomer = ref(new CrmCustomerEditorModel());
        const calendarBirthdate = ref<Date | null>(null);
        const crmCustomerForm = ref<InstanceType<typeof CrmCustomerForm>>();

        async function open(crmCustomerToEdit: CrmCustomerEditorModel | null = null) {
            swal.showLoading();
            const element = document.getElementById('crm-customer-editor');
            if (element) {
                element.classList.remove('was-validated');
            }
            await q.delay(400);
            if (crmCustomerToEdit) {
                originCrmCustomer.value = _.clone(crmCustomerToEdit);
                crmCustomer.value = _.clone(crmCustomerToEdit);
            }
            bussinesClients.value = await generalStore.dispatch('loadBusinessClients');
            calendarBirthdate.value = crmCustomerToEdit?.birthdate
                ? moment(crmCustomerToEdit.birthdate, DateConstants.DATE_FORMAT_SHOW).toDate()
                : null;
            isOpen.value = true;
            swal.close();
        }
        function close() {
            isOpen.value = false;
        }
        function birthDateChanged(event: Date) {
            calendarBirthdate.value = event;
            crmCustomer.value.birthdate = moment(event).format(DateConstants.DATE_FORMAT_SHOW);
        }
        async function onSave() {
            swal.showLoading();
            let changes;
            if (crmCustomer.value.id) {
                changes = getFlagChanges(originCrmCustomer.value, crmCustomer.value, CrmCustomerSaveChanges, key => {
                    if (key === 'businessClientId') return 'BusinessClient';
                    return null;
                });
            } else {
                changes = CrmCustomerSaveChanges.AllExcludeRemarks;
            }
            const req: SaveCrmCustomerRequest = {
                id: crmCustomer.value.id,
                firstName: crmCustomer.value.firstName,
                lastName: crmCustomer.value.lastName,
                email: crmCustomer.value.email,
                mobile: crmCustomer.value.mobile,
                birthdate: getBirthdateString(calendarBirthdate.value),
                businessClientId: crmCustomer.value.businessClientId,
                remarks: '',
                changes: changes
            };

            const apiResponse = await api.saveCrmCustomer(req);
            if (apiResponse.validationErrors?.length) {
                crmCustomerForm.value?.setValidator(apiResponse.validationErrors);
                swal.close();
                return null;
            }
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return null;
            }

            swal.close();
            const customer = apiResponse.data;
            return {
                birthdate: customer.birthdate,
                businessClientId: customer.businessClientId,
                email: customer.email,
                firstName: customer.firstName,
                lastName: customer.lastName,
                mobile: customer.mobile,
                id: customer.id,
                businessClientName:
                    bussinesClients.value.find((x: BussinesClientByNewOrderObject) => x.id === customer.businessClientId)?.name ?? ''
            } as CrmCustomerEditorModel;
        }
        const submitForm = async (event: any) => {
            crmCustomerForm.value?.clearValidator();
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            const crmCustomerToUpdate = await onSave();

            if (crmCustomerToUpdate == null) {
                return;
            }
            close();
            if (crmCustomer.value.id) {
                crmCustomerToUpdate.businessClientName =
                    bussinesClients.value.find((x: BussinesClientByNewOrderObject) => x.id == crmCustomer.value.businessClientId)?.name ??
                    '';
                emit('updatecrmCustomerDetails', crmCustomerToUpdate);
            } else {
                router.push({
                    name: 'crm-customer-details',
                    params: { id: String(crmCustomerToUpdate.id) }
                });
            }
        };

        return {
            isOpen,
            open,
            close,
            crmCustomer,
            bussinesClients,
            submitForm,
            birthDateChanged,
            crmCustomerForm,
            calendarBirthdate,
            originCrmCustomer
        };
    }
});
